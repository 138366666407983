export default {
  methods: {
    async refreshCurrentWebsite(newId, oldId) {
      if (newId && newId !== oldId) {
        try {
          await this.doFetchWebsite();
        } catch (error) {
          this.backendError = error.message;
        }
      }
    },
  },
  watch: {
    "$route.params.website_id": {
      handler: "refreshCurrentWebsite",
      immediate: true,
    },
  },
};
