<template>
  <div>
    <p v-if="isProvisioning">{{ $t("dg_suite.consent_management.still_provisioning") }}</p>
    <p class="consent-management__provision-success" v-else-if="wasProvisioned">
      {{ $t("dg_suite.consent_management.successfully_provisioned") }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    isProvisioning: {
      type: Boolean,
      default: false,
    },
    wasProvisioned: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.consent-management__provision-success {
  padding: 8px;
  background-color: lighten(#5bc88d, 25%);
  color: darken(#5bc88d, 25%);
  margin-bottom: 16px;
  display: block;
}
</style>
