<template>
  <div>
    <DgPanel :header="$t('dg_suite.consent_management.integration.title')">
      <ProvisioningStatus :isProvisioning="isProvisioning" :wasProvisioned="wasProvisioned" />
      <div v-if="areInstructionsShown">
        <div class="consent-management__instructions" v-html="instructions" />
      </div>
      <p v-if="backendError">{{ fetchingError }}</p>
      <DgLoadingIndicator
        :is-loading="isLoading"
        :label="$t(`dg_suite.consent_management.integration.loading_label`)"
      />
    </DgPanel>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Structural, Basic } from "@/dgui-customer-components/";
import ProvisioningStatus from "../../components/CookieManager/ProvisioningStatus";

import sharedFunctions from "../../mixins/shared-functions";
import refreshCurrentWebsite from "../../mixins/refresh-current-website";

// FIXME: This has some duplication with Configuration.vue
export default {
  name: "CookieManagerIntegration",
  mixins: [sharedFunctions, refreshCurrentWebsite],
  props: {
    wasProvisioned: Boolean,
  },
  components: {
    DgPanel: Structural.DgPanel,
    DgLoadingIndicator: Basic.DgLoadingIndicator,
    ProvisioningStatus,
  },
  data() {
    return {
      isLoading: !this.currentWebsite,
      backendError: null,
    };
  },
  computed: {
    ...mapState({
      currentWebsite: state => state.dg_suite.currentWebsite || {},
    }),
    areInstructionsShown() {
      return !(this.isLoading || this.backendError || this.isProvisioning);
    },
    instructions() {
      // TODO: dynamically decorate code blocks with a "copy to clipboard" button
      const { default: modern_browsers_javascript, legacy: legacy_browsers_javascript } =
        this.currentWebsite.javascript;
      const instructions = this.$t("dg_suite.consent_management.integration.instructions", {
        modern_browsers_javascript,
        legacy_browsers_javascript,
      });
      return this.markdown(instructions);
    },
  },
  methods: {
    ...mapActions(["fetchWebsite"]),
    copyPluginCode() {
      this.$clipboard(this.installation_instructions.javascript);
      this.$dgToast.show({
        type: "info",
        title: "Copied",
        content: "Copied Code to your clipboard",
        closeTimeout: 1500,
      });
    },
    async doFetchWebsite() {
      this.isLoading = true;

      try {
        await this.fetchWebsite(this.$route.params.website_id);
      } catch (error) {
        this.backendError = error.message;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
// NOTE: These are global styles because these tags come from Markdown conversion. Use the separate <style> tag
// above for your regular styling.
.consent-management__instructions {
  h2 {
    margin-top: 32px;
  }
  pre {
    padding: 14px;
    background: #f2f4f6;
  }
}
</style>
