import config from "../store/config";

export default {
  computed: {
    isProvisioning() {
      return this.currentWebsite && !this.currentWebsite.usercentrics_setting_id;
    },
    areMoreWebsitesPossible() {
      return Object.keys(this.websites).length < config.NUMBER_OF_ALLOWED_WEBSITES;
    },
  },
  methods: {
    categoryOptionFor(category) {
      return { code: category, label: this.categoryLabel(category) };
    },
    categoryLabel(category) {
      return this.$t(`dg_suite.consent_management.external_providers.category.options.${category}`);
    },
  },
};
